import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const router = new Router({
  mode: "hash",
  routes: [
    // {
    //     path: '/',
    //     name: '',
    //     component: () => import('@/view/index.vue'),
    // },
    {
      path: "/base",
      name: "base",
      component: () => import("@/view/base.vue"),
    },
    {
      path: "/baseOther",
      name: "baseOther",
      component: () => import("@/view/baseOther.vue"),
    },
    {
      path: "/baseDetail",
      name: "baseDetail",
      component: () => import("@/view/baseDetail.vue"),
    },
    {
      path: "/baseCompanyDetail",
      name: "baseCompanyDetail",
      component: () => import("@/view/baseCompanyDetail.vue"),
    },
    {
      path: "/projectDetail",
      name: "projectDetail",
      component: () => import("@/view/projectDetail.vue"),
    },
    {
      path: "/landDetail",
      name: "landDetail",
      component: () => import("@/view/landDetail.vue"),
    },
    {
      path: "/introduction",
      name: "introduction",
      component: () => import("@/view/v1.0/companyProfile.vue"),
    },
    {
      path: "/business",
      name: "business",
      component: () => import("@/view/business.vue"),
    },
    {
      path: "/businessDetail",
      name: "businessDetail",
      component: () => import("@/view/businessDetail.vue"),
    },
    {
      path: "/newsList",
      name: "newsList",
      component: () => import("@/view/newsList.vue"),
    },
    {
      path: "/newsListDetail",
      name: "newsListDetail",
      component: () => import("@/view/newsListDetail.vue"),
    },
    {
      path: "/companyNewsListDetail",
      name: "companyNewsListDetail",
      component: () => import("@/view/companyNewsListDetail.vue"),
    },
    {
      path: "/projectSchedule",
      name: "projectSchedule",
      component: () => import("@/view/projectSchedule.vue"),
    },
    {
      path: "/projectScheduleList",
      name: "projectScheduleList",
      component: () => import("@/view/projectScheduleList.vue"),
    },
    {
      path: "/PublicityDocumentList",
      name: "PublicityDocumentList",
      component: () => import("@/view/PublicityDocumentList.vue"),
    },
    {
      path: "/PublicityDocument",
      name: "PublicityDocument",
      component: () => import("@/view/PublicityDocument.vue"),
    },
    {
      path: "/m",
      name: "m",
      component: () => import("@/view/mIndex.vue"),
    },
    // 首页
    // {
    //     path: '/',
    //     name: '',
    // },
    // 首页 v1.0
    {
      path: "/",
      name: "",
      // component: () => import('@/view/index1.vue'),
      component: () => import("@/view/v1.0/index.vue"),
    },
    //省公司
    {
      path: "/ProvincialCompany",
      name: "ProvincialCompany",
      component: () => import("@/view/v1.0/ProvincialCompany.vue"),
    },
    //省公司
    {
      path: "/cityCompany",
      name: "cityCompany",
      component: () => import("@/view/v1.0/cityCompany.vue"),
    },
    //能源版块
    {
      path: "/EnergySector",
      name: "EnergySector",
      component: () => import("@/view/v1.0/EnergySector.vue"),
    },
    //药材版块
    {
      path: "/medicinalSector",
      name: "medicinalSector",
      component: () => import("@/view/v1.0/medicinalSector.vue"),
    },
    //药材合集
    {
      path: "/Specific_m",
      name: "Specific_m",
      component: () => import("@/view/v1.0/Specific_m.vue"),
    },
    //药材详情
    {
      path: "/medicineDetails",
      name: "medicineDetails",
      component: () => import("@/view/v1.0/medicineDetails.vue"),
    },
    //药材详情
    {
      path: "/medicinal",
      name: "medicinal",
      component: () => import("@/view/v1.0/medicinal.vue"),
    },
    //区域
    {
      path: "/areaCompany",
      name: "areaCompany",
      component: () => import("@/view/v1.0/areaCompany.vue"),
    },

    // 关于我们
    // {
    //     path: '/introduction',
    //     name: 'introduction',
    //     component: () => import('@/view/introduction.vue'),
    // },
    // 关于我们
    {
      path: "/companyProfile",
      name: "companyProfile",
      component: () => import("@/view/v1.0/companyProfile.vue"),
    },
    // 药材基地
    // {
    //     path: '/medicinal',
    //     name: 'medicinal',
    //     component: () => import('@/view/medicinal.vue'),
    // },
    // 数据中心
    {
      path: "/dataCenter",
      name: "dataCenter",
      component: () => import("@/view/dataCenter.vue"),
    },
  ],
});
export default router;
