import Vue from "vue";
import Vuex from "vuex";

//1.安装插件
Vue.use(Vuex);

//2.创建对象
export default new Vuex.Store({
  state: {
    access_token: "",
    companyTier: [],
    company: {},
  },
  mutations: {
    setAccess_token(state, n) {
      state.access_token = n;
    },
    setCompany(state, n) {
      let companyTier = state.companyTier;
      if (!companyTier.length) {
        companyTier = JSON.parse(localStorage.getItem("companyTier"));
      }
      state.company = n;
      localStorage.setItem("companyInfo", JSON.stringify(n));
      if (n.companyLevel == 1) {
        state.companyTier = [3];
        localStorage.setItem("companyTier", JSON.stringify(state.companyTier));
      }
      if (n.companyLevel == 2) {
        state.companyTier = [3, n.companyId];
        localStorage.setItem("companyTier", JSON.stringify(state.companyTier));
      }
      if (n.companyLevel == 3) {
        state.companyTier = [3, companyTier[1], n.companyId];
        localStorage.setItem("companyTier", JSON.stringify(state.companyTier));
      }
      if (n.companyLevel == 4) {
        state.companyTier = [3, companyTier[1], companyTier[2], n.companyId];
        localStorage.setItem("companyTier", JSON.stringify(state.companyTier));
      }
    },
    setCompanyTier(state, n) {
      state.companyTier = n;
      localStorage.setItem("companyTier", JSON.stringify(n));
    },
  },
  actions: {},
  getters: {
    getAccess_token(state) {
      return state.access_token;
    },
    getCompany(state) {
      let company = {};
      if (!state.company.companyId) {
        if (!localStorage.getItem("companyInfo")) {
          company = {
            companyId: 3,
            companyName: "中峪农业",
            websiteId: 3,
            companyDesc:
              "浙江中峪农业发展有限责任公司，自成立以来，一直以守护道地药材为初心，传承发扬中华传统中医药产业为己任。我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，始终坚持在原产地种植，确保药材纯正品质，将在全国范围内建立多个道地药材种植基地，严格遵循地域特性、自然规律和药材生长习性，确保药材在最佳生长环境中茁壮成长，致力于为更多人提供高品质、纯天然的中药材，为传统中医药文化焕发新的活力。\n中峪农业将始终秉承“传承经典、创新发展”的理念，不断提升道地药材的品质和效益。我们将积极拓展国际市场，将中华传统药材文化推向世界舞台，让更多人了解和受益于中医药的神奇魅力。",
            companyDirector: "admin",
            companyImage:
              "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-07/1307cf97c1554c9388754c9420f3421f.png",
            pid: 0,
            fullCompanyId: ":3:",
            companyLevel: 1,
            companyLocation: "浙江省",
            listingStatus: 1,
            companyCategory: 2,
            adCode: "100000",
            lxy: '["120","23"]',
            isDelete: false,
            createTime: "2024-01-12 15:27:22",
            createBy: null,
            updateTime: "2024-09-06 11:09:52",
            updateBy: null,
            website: null,
          };
        } else {
          company = JSON.parse(localStorage.getItem("companyInfo"));
        }
      } else {
        company = state.company;
      }
      return company;
    },
    getCompanyTier(state) {
      let companyTier = {};
      if (!state.companyTier.length) {
        companyTier = JSON.parse(localStorage.getItem("companyTier"));
      } else {
        companyTier = state.companyTier;
      }
      return companyTier;
    },
  },
  modules: {},
});
